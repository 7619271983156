<!-- 酒店管理-房源明细 -->

<template>
  <div class="property-details-dialog">
    <w-dialog ref="dialogRef" title="房源明细" width="55%" top="10vh" :hideFooter="true">
      <div class="">
        <div class="flex-btw top-title">
          <div class="hrt-name">{{ currentRow.hrt_name }}</div>
          <div>
            <el-button type="primary" @click="onClickAddBtn" class="add-btn" v-if="authData.indexOf('h_JVTJt3nV2ibidLKfLNdc97fsy3BT') != -1">添加房间</el-button>
          </div>
        </div>
        <div class="dialog-content s-m-t">
          <div class="flex flex-wrap">
            <div v-if="infoDatas.length == 0" class="no-data">暂无数据</div>
            <div class="info-content" v-for="(item, index) in infoDatas" :key="index">
              <div class="info-room-title text-hidden">{{ item.hrt_name }}</div>
              <div class="s-m-t">房间号：{{ item.hrl_number }}</div>
              <div class="xs-m-t">
                <span>操 作：</span>
                <span class="text-blue cursor-pointer" @click="onDownloadCode(item)">下载二维码</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </w-dialog>

    <!-- 添加房间 -->
    <w-dialog ref="addDialogRef" class="room-type" title="添加房间" width="30%" top="10vh" @wConfirm="wConfirm">
      <div class="add-dialog-content">
        <div>请输入房间号</div>
        <div class="s-m-t">
          <el-input v-model="hrl_number" clearable placeholder="请输入房间号"></el-input>
        </div>
      </div>
    </w-dialog>
  </div>
</template>

<script>
  import { ref, computed, watch, } from "vue";
  import { ElMessage, } from "element-plus";
  import { HotelApi, } from "@/plugins/api.js";
  import { useStore } from "vuex";

  export default {
    emits: ["submit"],
    setup(props, { emit }) {
      const store = useStore();
      const menuTokens = computed(() => store.state.menuToken.menuTokens);
      const authData = ref([]);
      watch(
        () => menuTokens.value,
        (data) => {
          if (data.length) {
            authData.value = data;
          }
        },
        {
          deep: true,
          immediate: true,
        }
      );
      const dialogRef = ref(null);  // 弹框对象
      const currentRow = ref('');  // 当前行数据
      const infoDatas = ref([]);  // 详情数据
      const addDialogRef = ref(null);  // 添加房间对象
      const hrl_number = ref('');  // 房间号

      /**
       * 
       * 打开对话框
       * 
       * **/
      const openDialog = (row) => {
        currentRow.value = row;
        getListbyhidandtid();
      }
      /**
       * 
       * 关闭对话框
       * 
       * **/
      const closeDialog = () => {
        dialogRef.value.close();
      }
      /**
       * 
       * 打开对话框加载状态
       * 
       * **/
      const closeDialogLoading = () => {
        dialogRef.value.isLoading = false;
      }
      /**
       * 
       * 获取详情数据
       * 
       * **/
      const getListbyhidandtid = () => {
        HotelApi.getListbyhidandtid({ hid: currentRow.value.hrt_hid, hrt_id: currentRow.value.hrt_id, }).then((res) => {
          if (res.Code === 200) {
            infoDatas.value = res.Data.list;
            dialogRef.value.show();
          } else {
            ElMessage.error(res.Message);
          }
        });
      }
      /**
       * 
       * 添加房间按钮
       * 
       * **/
      const onClickAddBtn = () => {
        hrl_number.value = '';
        addDialogRef.value.show();
      }
      /**
       * 
       * 确定添加房间按钮
       * 
       * **/
      const wConfirm = () => {
        if (!hrl_number.value) {
          ElMessage.error("请输入房间号！");
          return false;
        }
        HotelApi.addRoom({
          hid: currentRow.value.hrt_hid,
          hrt_id: currentRow.value.hrt_id,
          hrl_number: hrl_number.value,
        }).then((res) => {
          if (res.Code === 200) {
            ElMessage.success("添加成功！");
            getListbyhidandtid();
            emit('submit');
            addDialogRef.value.close();
          } else {
            ElMessage.error(res.Message);
          }
        });
      }
      /**
       * 
       * 下载二维码
       * 
       * */
      const onDownloadCode = (row) => {
        // 创建a标签
        const link = document.createElement('a');
        link.href = process.env.VUE_APP_API_HOST + `/hotel/hotel_qr/getqr.api?hrl_id=${row.hrl_id}`;
        link.click();
        URL.revokeObjectURL(link.href);
      }


      return {
        openDialog,
        dialogRef,
        closeDialog,
        closeDialogLoading,
        currentRow,
        getListbyhidandtid,
        infoDatas,
        addDialogRef,
        onClickAddBtn,
        hrl_number,
        wConfirm,
        onDownloadCode,
        authData,
      };
    },
  };
</script>

<style lang="scss">
  .property-details-dialog {
    .dialog-content {
      min-height: 500px;
      max-height: 500px;
      overflow: auto;
    }

    .xs-m-t {
      margin-top: 5px;
    }

    .s-m-t {
      margin-top: 10px;
    }

    .text-blue {
      color: var(--theme-color);
    }

    .top-title {
      margin: 0 20px 0 9px;
    }

    .hrt-name {
      color: var(--text-color);
      font-weight: bold;
      font-size: 18px;
    }

    .add-btn {
      width: 100px;
      padding: 0;
      min-height: 32px;
      max-height: 32px;
    }

    .info-content {
      background-color: var(--search-bg-color);
      border-radius: 4px;
      color: var(--text-color);
      margin: 10px;
      padding: 10px;
      width: 179px;
    }

    .info-room-title {
      background-color: var(--theme-color);
      color: var(--text-white-color);
      border-radius: 4px;
      padding: 2px 5px;
      max-width: 92px;
      text-align: center;
      font-size: 12px;
    }

    .add-dialog-content {
      min-height: 240px;
    }

    .no-data {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      text-align: center;
      color: var(--text-gray-color);
    }
  }
</style>