<script setup>
import { ref, reactive, onMounted, computed, watch } from "vue";
import { ElMessage } from "element-plus";
// 导入图片上传组件
import imgUpload from "@/components/img-upload/img-upload.vue";
import { BasicApi, HotelApi } from "@/plugins/api.js";
import { useStore } from "vuex";
import PropertyDetails from "../components/PropertyDetails.vue";

const store = useStore();
const menuTokens = computed(() => store.state.menuToken.menuTokens);
const authData = ref([]);
watch(
  () => menuTokens.value,
  (data) => {
    if (data.length) {
      authData.value = data;
    }
  },
  {
    deep: true,
    immediate: true,
  }
);
/** 户型表格对象 */
const typeTable = ref(null);
/** 筛选条件列表 */
const filters = ref([
  {
    filterType: "select",
    name: "hid",
    value: "",
    placeholder: "请选择酒店",
    noclear: true,
    options: [],
    lab: "h_name",
    val: "h_id",
  },
]);
/** 表格配置数据 */
const tableColumns = ref([
  {
    prop: "hrt_id",
    label: "房型ID",
    minWidth: 120,
    color: "--text-color",
  },
  {
    prop: "hrt_name",
    label: "房型名称",
    minWidth: 120,
    color: "--text-color",
  },
  {
    type: "block",
    prop: "hrt_number",
    label: "房间数量",
    active: "onRoomsNum",
    minWidth: 60,
    token: "h_oQMtuRne0N3GheIkVxJgoXAtC6py",
  },
  {
    prop: "hrt_price",
    label: "默认价格",
    minWidth: 120,
    color: "--text-third-color",
  },
  {
    type: "switch",
    prop: "hrt_status",
    label: "房型状态",
    minWidth: 120,
    token: "h_oQMtuRne0N3GheIkVxJgoXAt3333",
  },
  {
    type: "block",
    prop: "file_number",
    label: "房型图片",
    minWidth: 80,
    token: "h_oQMtuRne0N3GheIkVxJgoXAt4444",
  },
  {
    type: "operation",
    prop: "",
    label: "操作",
    minWidth: 100,
    bottons: [
      {
        name: "编辑",
        action: "edit",
        token: "h_oQMtuRne0N3GheIkVxJgoXAt2222",
        className: "theme-font-btn",
      },
    ],
  },
]);
/** 当前操作行数据 */
const currentRow = ref(null);
/** 状态改变处理 */
const statusChange = (row) => {
  let data = {
    hrt_id: row.hrt_id,
    hrt_status: row.hrt_status == 1 ? 2 : 1,
  };
  HotelApi.setRoomTypeStatus(data).then((res) => {
    if (res.Code === 200) {
      ElMessage.success("房型状态修改成功！");
      // 重新获取标签列表数据
      typeTable.value.tableLoad();
    } else {
      let msg = res.Message ? res.Message : "房型状态修改失败！";
      ElMessage.error(msg);
    }
  });
};
/** 查看图册 */
const showRoomType = (row) => {
  currentRow.value = row;
  roomType.value.show();
  store.dispatch("getQiniuData");
  getRoomTypeImages();
};
/** 表格编辑 */
const comboEdit = (row) => {
  currentRow.value = row;
  isEdit.value = true;
  addDialog.value.show();
  // 获取户型详情数据
  getRoomTypeInfo();
};
/** 获取户型详情数据 */
const getRoomTypeInfo = () => {
  HotelApi.roomTypeInfo({ hrt_id: currentRow.value.hrt_id }).then((res) => {
    if (res.Code === 200) {
      ruleForm.name = res.Data.hrt_name;
      ruleForm.number = res.Data.hrt_number;
      ruleForm.price = res.Data.hrt_price;
      ruleForm.village = res.Data.hrt_pid;
      ruleForm.hotel = res.Data.hrt_hid;
      ruleForm.tagVal = res.Data.hrt_lable;
      hotelOptions.value = [];
      if (res.Data.hrt_pid) {
        BasicApi.getHotelByProject({ pid: res.Data.hrt_pid }).then((res) => {
          if (res.Code === 200) {
            hotelOptions.value = res.Data;
          } else {
            let msg = res.Message ? res.Message : "获取酒店数据失败！";
            ElMessage.error(msg);
          }
        });
      }
    } else {
      let msg = res.Message ? res.Message : "获取户型详情数据失败！";
      ElMessage.error(msg);
    }
  });
};

/** 新增/编辑弹框 */
const addDialog = ref(null);
/** 当前是否是编辑操作 */
const isEdit = ref(false);
/** 表单对象 */
const addForm = ref(null);
/** 表单数据对象 */
const ruleForm = reactive({
  village: "", // 项目
  hotel: "", // 酒店
  name: "", // 套餐名称
  number: "", // 房间数量
  price: "", // 默认价格
  tagVal: [], // 标签选用
});
/** 表单规则对象 */
const rules = reactive({
  village: [
    {
      required: true,
      message: "请选择所属项目",
      trigger: "change",
    },
  ],
  hotel: [
    {
      required: true,
      message: "请选择所属酒店",
      trigger: "change",
    },
  ],
  name: [
    {
      required: true,
      message: "请输入房型名称",
      trigger: "blur",
    },
  ],
  number: [
    {
      required: true,
      message: "请输入房间数量",
      trigger: "blur",
    },
  ],
  price: [
    {
      required: true,
      message: "请输入默认价格",
      trigger: "blur",
    },
  ],
  tagVal: [
    {
      required: true,
      message: "请选择房型特色标签",
      trigger: "change",
    },
  ],
});
/** 弹出 新增房型 弹框 */
const showAddDialog = () => {
  isEdit.value = false;
  // 表单验证重置
  if (addForm.value) {
    addForm.value.resetFields();
  }
  ruleForm.village = "";
  ruleForm.hotel = "";
  ruleForm.name = "";
  ruleForm.number = "";
  ruleForm.price = "";
  ruleForm.tagVal = "";
  hotelOptions.value = [];
  addDialog.value.show();
};

/** 房型图片 弹框 */
const roomType = ref(null);
/** 房型图片数据 */
const rtImage = ref(null);
/** 是否正在上传图片 */
const fullLoading = ref(false);
/** 多文件上传列表 */
const mulFileObj = ref({
  hrt_id: "",
  sft_id: "",
  file_type: "",
  files: [],
  cover_files: [],
});
/** 获取房型图片数据 */
const getRoomTypeImages = () => {
  HotelApi.getRoomTypePhoto({ hrt_id: currentRow.value.hrt_id }).then((res) => {
    if (res.Code === 200) {
      rtImage.value = res.Data;
    } else {
      let msg = res.Message ? res.Message : "获取房型图片数据失败！";
      ElMessage.error(msg);
    }
  });
};
/** 文件上传 */
const uploadFile = (data, isCover) => {
  if (authData.value.indexOf("h_oQMtuRne0N3GheIkVxJgoXAt5555") != -1) {
    fullLoading.value = true;
    mulFileObj.value.hrt_id = currentRow.value.hrt_id;
    mulFileObj.value.sft_id = rtImage.value.sft_id;
    mulFileObj.value.file_type = 1;
    if (isCover) {
      mulFileObj.value.cover_files.push(data.key);
    } else {
      mulFileObj.value.files.push(data.key);
    }
    // 去抖上传
    debounce(uploadSubmit, 300);
  } else {
    ElMessage.warning("您没有操作的权限！");
  }
};
/** 定时器 id */
const timer = ref(null);
/** 去抖函数 */
const debounce = (fn, delay) => {
  if (timer.value) {
    clearTimeout(timer.value);
  }
  timer.value = setTimeout(fn, delay);
};
/** 上传提交 */
const uploadSubmit = () => {
  HotelApi.addRoomTypePhoto(mulFileObj.value).then((res) => {
    if (res.Code === 200) {
      ElMessage.success("上传成功！");
      // 获取一次图册数据
      getRoomTypeImages();
    } else {
      let msg = res.Message ? res.Message : "上传失败！";
      ElMessage.error(msg);
    }
    fullLoading.value = false;
  });
  mulFileObj.value = {
    hrt_id: "",
    sft_id: "",
    file_type: "",
    files: [],
    cover_files: [],
  };
};
/** 文件删除 */
const deleteFile = (data) => {
  if (authData.value.indexOf("h_oQMtuRne0N3GheIkVxJgoXAt6666") != -1) {
    HotelApi.delRoomTypePhoto({ sf_id: data.sf_id }).then((res) => {
      if (res.Code === 200) {
        ElMessage.success("图片删除成功！");
        // 获取一次图册数据
        getRoomTypeImages();
      } else {
        let msg = res.Message ? res.Message : "图片删除失败！";
        ElMessage.error(msg);
      }
    });
  } else {
    ElMessage.warning("您没有操作的权限");
  }
};

/** 酒店列表数据 */
const hotelOptions = ref([]);
/** 获取酒店列表数据 */
const getHotelData = () => {
  BasicApi.getHotelByProject().then((res) => {
    if (res.Code === 200) {
      filters.value[0].options = res.Data;
      filters.value[0].value = res.Data[0].h_id;
      if (filters.value[0].value) {
        typeTable.value.tableLoad();
      }
    } else {
      let msg = res.Message ? res.Message : "获取酒店数据失败！";
      ElMessage.error(msg);
    }
  });
};
/** 项目列表数据 */
const projectOptions = ref([]);
/** 获取项目数据 */
const getProjectData = () => {
  BasicApi.projectSelect().then((res) => {
    if (res.Code === 200) {
      projectOptions.value = res.Data ? res.Data : [];
    } else {
      let msg = res.Message ? res.Message : "获取项目数据失败！";
      ElMessage.error(msg);
    }
  });
};
/** 项目选择更改 */
const projectChange = (value) => {
  hotelOptions.value = [];
  ruleForm.hotel = "";
  if (value) {
    BasicApi.getHotelByProject({ pid: value }).then((res) => {
      if (res.Code === 200) {
        hotelOptions.value = res.Data;
      } else {
        let msg = res.Message ? res.Message : "获取酒店数据失败！";
        ElMessage.error(msg);
      }
    });
  }
};

/** 标签列表数据 */
const tagOptions = ref([]);
/** 获取标签列表数据 */
const getTagDatas = () => {
  BasicApi.roomLable().then((res) => {
    if (res.Code === 200) {
      tagOptions.value = res.Data ? res.Data : [];
    } else {
      let msg = res.Message ? res.Message : "获取标签列表数据失败！";
      ElMessage.error(msg);
    }
  });
};

/** 新增/编辑提交处理 */
const confirm = () => {
  addForm.value.validate((valid) => {
    if (valid) {
      addDialog.value.isLoading = true;
      let data = {
        hrt_name: ruleForm.name,
        hrt_number: ruleForm.number,
        hrt_price: ruleForm.price,
        hrt_pid: ruleForm.village,
        hrt_hid: ruleForm.hotel,
        hrt_lable: ruleForm.tagVal.join(","),
      };
      let url = "addRoomType";
      if (isEdit.value) {
        url = "editRoomType";
        data.hrt_id = currentRow.value.hrt_id;
      }
      HotelApi[url](data).then((res) => {
        addDialog.value.isLoading = false;
        const text = isEdit.value ? "修改" : "新增";
        if (res.Code === 200) {
          ElMessage.success(`房型${text}成功！`);
          addDialog.value.close();
          // 重新获取标签列表数据
          typeTable.value.tableLoad();
        } else {
          let msg = res.Message ? res.Message : `房型${text}失败！`;
          ElMessage.error(msg);
        }
      });
    }
  });
};

const roomsInfoRef = ref(null);  // 房源明细对象
/**
 *    
 * 点击房间数量
 * 
 * **/
const onRoomsNum = (row) => {
  roomsInfoRef.value.openDialog(row);
}
/**
 *    
 * 重新获取酒店列表数据
 * 
 * **/
const onSubmit = () => {
  typeTable.value.tableLoad();
}

onMounted(() => {
  // 获取酒店列表
  getHotelData();
  // 获取项目列表
  getProjectData();
  getTagDatas();
});
</script>

<template>
  <div class="tmanager main-cnt">
    <div class="title">房型列表</div>
    <div class="content">
      <common-table
        ref="typeTable"
        tableHeight="calc(100vh - 325px)"
        :ischeck="false"
        :ispaging="true"
        :apiName="HotelApi.roomTypeList"
        :filters="filters"
        :columns="tableColumns"
        @statusChange="statusChange"
        @showAlbum="showRoomType"
        @edit="comboEdit"
        @onRoomsNum="onRoomsNum"
      >
        <template #operate>
          <el-button
            type="primary"
            round
            @click="showAddDialog"
            v-if="authData.indexOf('h_oQMtuRne0N3GheIkVxJgoXAt1111') != -1"
          >
            <el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
            新增房型</el-button
          >
        </template>
      </common-table>
    </div>

    <!-- 新增/编辑房型弹框 -->
    <w-dialog
      ref="addDialog"
      class="add-dialog"
      :title="isEdit ? '编辑房型' : '新增房型'"
      width="55%"
      btnWidth="140px"
      top="10vh"
      @wConfirm="confirm"
    >
      <el-form
        class="add-form"
        ref="addForm"
        :model="ruleForm"
        :rules="rules"
        labelPosition="top"
      >
        <el-form-item label="所属项目" prop="village">
          <el-select
            v-model="ruleForm.village"
            clearable
            placeholder="请选择所属项目"
            @change="projectChange"
          >
            <el-option
              :label="item.p_name"
              :value="item.p_id"
              v-for="item in projectOptions"
              :key="item.p_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属酒店" prop="hotel">
          <el-select
            v-model="ruleForm.hotel"
            clearable
            placeholder="请选择所属酒店"
          >
            <el-option
              :label="item.h_name"
              :value="item.h_id"
              v-for="item in hotelOptions"
              :key="item.h_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item label="房型名称" prop="name">
          <el-input
            v-model="ruleForm.name"
            placeholder="请输入房型名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="房间数量" prop="number">
          <el-input
            v-model="ruleForm.number"
            type="number"
            placeholder="请输入房间数量"
          ></el-input>
        </el-form-item>
        <el-form-item label="默认价格" prop="price">
          <el-input
            v-model="ruleForm.price"
            type="number"
            placeholder="请输入默认价格"
          ></el-input>
        </el-form-item>
        <el-form-item label="标签选用" prop="tagVal">
          <el-select
            v-model="ruleForm.tagVal"
            clearable
            multiple
            collapse-tags
            placeholder="请选择房型特色标签"
          >
            <el-option
              :label="item.hrl_name"
              :value="item.hrl_id"
              v-for="item in tagOptions"
              :key="item.hrl_id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </w-dialog>

    <!-- 房型图片 弹框 -->
    <w-dialog
      ref="roomType"
      class="room-type"
      title="房型图片编辑"
      width="55%"
      btnWidth="140px"
      :hideFooter="true"
      top="10vh"
      v-loading.fullscreen.lock="fullLoading"
      @wConfirm="roomType.close()"
    >
      <!-- 图片上传部分 -->
      <div class="name">房型图片</div>
      <img-upload
        :limit="5"
        uploadTitle="房型图片"
        :fileList="rtImage && rtImage.files ? rtImage.files : []"
        @uploadFile="uploadFile"
        @deleteFile="deleteFile"
        suggestText="建议尺寸702*292px，不超过2MB"
      ></img-upload>
      <!-- 上传封面 -->
      <div class="name">上传封面</div>
      <img-upload
        :limit="1"
        :fileList="rtImage && rtImage.cover_files ? rtImage.cover_files : []"
        @uploadFile="uploadFile($event, true)"
        @deleteFile="deleteFile"
        suggestText="建议尺寸702*292px，不超过2MB"
      ></img-upload>
    </w-dialog>

    <PropertyDetails ref="roomsInfoRef" @submit="onSubmit"></PropertyDetails>
  </div>
</template>

<style lang="scss">
.tmanager {
  font-family: "Source Han Sans CN";
  .content {
    padding: 20px;
  }
  .add-dialog {
    .el-dialog {
      min-width: 840px;
      .el-dialog__body {
        padding: 0 15px 30px;
        .add-form {
          display: flex;
          flex-wrap: wrap;
          .el-form-item {
            width: 33.3%;
            padding: 20px 15px 0;
            margin-bottom: 0;
            .el-form-item__content {
              .el-select {
                width: 100%;
                .el-input {
                  width: 100%;
                }
              }
            }
          }
          .el-form-item.upload {
            width: 100%;
          }
          .el-divider {
            margin: 30px 0 0 15px;
            width: calc(100% - 30px);
          }
        }
      }
    }
  }
  .room-type {
    .name {
      color: var(--text-color);
      margin-bottom: 4px;
    }
    .img-upload {
      margin-bottom: 20px;
    }
  }
}
</style>
